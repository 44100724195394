var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';
// ----------------------------------------------------------------------
var Loadable = function (Component) { return function (props) {
    return (_jsx(Suspense, __assign({ fallback: _jsx(LoadingScreen, {}) }, { children: _jsx(Component, __assign({}, props)) })));
}; };
// ----------------------------------------------------------------------
export var LoginPage = Loadable(lazy(function () { return import('../pages/LoginPage'); }));
export var PermissionGroup = Loadable(lazy(function () { return import('../pages/PermissionGroup'); }));
///
export var UserList = Loadable(lazy(function () { return import('../pages/User/UserList'); }));
export var UserView = Loadable(lazy(function () { return import('../pages/User/UserView'); }));
export var UserEdit = Loadable(lazy(function () { return import('../pages/User/UserEdit'); }));
export var Calendar = Loadable(lazy(function () { return import('../pages/Calendar'); }));
export var Analytics = Loadable(lazy(function () { return import('../pages/Analytics'); }));
// kpis
export var Kpis = Loadable(lazy(function () { return import('../pages/Kpis/KpisListPage'); }));
export var KpiNew = Loadable(lazy(function () { return import('../pages/Kpis/KpiNewPage'); }));
export var KpiEdit = Loadable(lazy(function () { return import('../pages/Kpis/KpiEditPage'); }));
export var KpiView = Loadable(lazy(function () { return import('../pages/Kpis/KpiDetailsPage'); }));
// client status response
export var StatClientResponseList = Loadable(lazy(function () { return import('../pages/StatClientResponse/StatClientResListPage'); }));
export var StatClientResponseNew = Loadable(lazy(function () { return import('../pages/StatClientResponse/StatClientResNewPage'); }));
export var StatClientResponseView = Loadable(lazy(function () { return import('../pages/StatClientResponse/StatClientResponseView'); }));
export var StatClientResponseEdit = Loadable(lazy(function () { return import('../pages/StatClientResponse/StatClientResponseEdit'); }));
export var Call = Loadable(lazy(function () { return import('../pages/Calls'); }));
export var StatsClient = Loadable(lazy(function () { return import('../pages/statsClient'); }));
export var StatsClientNew = Loadable(lazy(function () { return import('../pages/statsClient/statsClientNew'); }));
export var StatsClientEdit = Loadable(lazy(function () { return import('../pages/statsClient/statsClientEdit'); }));
export var StatsClientShow = Loadable(lazy(function () { return import('../pages/statsClient/statsClientShow'); }));
// todos
export var MyTodoList = Loadable(lazy(function () { return import('../pages/Todos/TodoListPage'); }));
export var TodoView = Loadable(lazy(function () { return import('../pages/Todos/TodoViewPage'); }));
// Notification
export var Notifications = Loadable(lazy(function () { return import('../pages/Notifications'); }));
// Offices
export var Offices = Loadable(lazy(function () { return import('../pages/Offices'); }));
export var OfficesEdit = Loadable(lazy(function () { return import('../pages/Offices/OfficesEdit'); }));
// Notes
export var NotesList = Loadable(lazy(function () { return import('../pages/Notes/NotesListPage'); }));
export var NoteNew = Loadable(lazy(function () { return import('../pages/Notes/NoteNewPage'); }));
export var NoteEdit = Loadable(lazy(function () { return import('../pages/Notes/NoteEditPage'); }));
export var NoteDetails = Loadable(lazy(function () { return import('../pages/Notes/NoteDetailsPage'); }));
// ========== Finances =========
// expenses
export var ExpensesList = Loadable(lazy(function () { return import('../pages/Finances/Expenses/ExpensesListPage'); }));
export var ExpenseNew = Loadable(lazy(function () { return import('../pages/Finances/Expenses/ExpenseNewPage'); }));
export var ExpenseDetails = Loadable(lazy(function () { return import('../pages/Finances/Expenses/ExpenseDetails'); }));
export var ExpenseEdit = Loadable(lazy(function () { return import('../pages/Finances/Expenses/ExpenseEditPage'); }));
// Codes
export var CodeList = Loadable(lazy(function () { return import('../pages/Finances/Codes/CodesListPage'); }));
export var CodeEdit = Loadable(lazy(function () { return import('../pages/Finances/Codes/CodesEditPage'); }));
export var CodeView = Loadable(lazy(function () { return import('../pages/Finances/Codes/CodesViewPage'); }));
// general cash register
export var CashRegisterList = Loadable(lazy(function () { return import('../pages/Finances/General-cash-register/CashRegisterList'); }));
export var CashRegisterNew = Loadable(lazy(function () { return import('../pages/Finances/General-cash-register/CashRegisterNew'); }));
export var CashRegisterEdit = Loadable(lazy(function () { return import('../pages/Finances/General-cash-register/CashRegisterEdit'); }));
export var CashRegisterView = Loadable(lazy(function () { return import('../pages/Finances/General-cash-register/CashPaymentDetails'); }));
// File Manager
export var FileManagerList = Loadable(lazy(function () { return import('../pages/FileManager/FileManagerShowPage'); }));
// SESSIONS
export var SessionList = Loadable(lazy(function () { return import('../pages/Sessions/SessionsList'); }));
export var SessionCalendar = Loadable(lazy(function () { return import('../pages/Sessions/SessionCalendar'); }));
// Configuration
export var BounusAmountList = Loadable(lazy(function () { return import('../pages/Configuration/BounusAmount/BounusAmountList'); }));
export var BonusAmountNew = Loadable(lazy(function () { return import('../pages/Configuration/BounusAmount/BonusAmountNew'); }));
export var BonusAmountView = Loadable(lazy(function () { return import('../pages/Configuration/BounusAmount/BonusAmountView'); }));
export var BonusAmountEdit = Loadable(lazy(function () { return import('../pages/Configuration/BounusAmount/BonusAmountEdit'); }));
// Receipt payment
export var ReceiptPaymentList = Loadable(lazy(function () { return import('../pages/Configuration/ReceiptPayment/ReceiptPaymentList'); }));
export var ReceiptPaymentNew = Loadable(lazy(function () { return import('../pages/Configuration/ReceiptPayment/ReceiptPaymentNew'); }));
export var ReceiptPaymentFill = Loadable(lazy(function () { return import('../pages/Configuration/ReceiptPayment/ReceiptPaymentFill'); }));
export var ReceiptPaymentView = Loadable(lazy(function () { return import('../pages/Configuration/ReceiptPayment/view'); }));
export var ReceiptPaymentEdit = Loadable(lazy(function () { return import('../pages/Configuration/ReceiptPayment/edit'); }));
// Payment Notes
export var PaymentNoteList = Loadable(lazy(function () { return import('../pages/Configuration/PaymentNotes'); }));
export var PaymentNoteNew = Loadable(lazy(function () { return import('../pages/Configuration/PaymentNotes/PaymentNotesNew'); }));
export var PaymentNoteEdit = Loadable(lazy(function () { return import('../pages/Configuration/PaymentNotes/PaymentNotesEdit'); }));
export var Page404 = Loadable(lazy(function () { return import('../pages/Page404'); }));
export var Page403 = Loadable(lazy(function () { return import('../pages/Page403'); }));
